import { mapGetters } from 'vuex'
import sanitizeHtml from 'sanitize-html'
import Breadcrumbs from 'theme/components/core/Breadcrumbs'
import config from 'config'

export default {
  components: {
    Breadcrumbs
  },
  data () {
    return {
      loading: true
    }
  },
  serverPrefetch () {
    return this.fetchPosts()
  },
  mounted () {
    this.fetchPosts()
  },
  watch: {
    '$route.name': function () {
      this.fetchPosts()
    },
    $route: {
      immediate: true,
      handler (to) {
        if (to.query.page) {
          this.changePage(parseInt(to.query.page) || 1);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      posts: 'wordpress/posts',
      allPages: 'wordpress/allPages',
      currentPage: 'wordpress/currentPage',
      currentEdges: 'wordpress/currentEdges',
      currentCategory: 'wordpress/currentCategory',
      categoriesPreview: 'wordpress/categoriesPreview'
    }),
    categoryTitle () {
      return this.currentCategory && this.currentCategory.title
    },
    postList () {
      if (this.posts && this.posts.length > 0) {
        return this.posts.slice(1)
      } else {
        return []
      }
    }
  },
  filters: {
    truncate: function (text, length, suffix) {
      return text.length > 0 ? text.substring(0, length) + suffix : ''
    },
    prettyDate: function (date) {
      let newDate = new Date(date.replace(/-/g, '/'))
      let dateFormat = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      return newDate.toLocaleDateString('pl-PL', dateFormat)
    }
  },
  methods: {
    async changePage (page = this.currentPage) {
      if (this.$route.params.slug) {
        await this.$store.dispatch('wordpress/loadCategoryPosts', { currentPage: Number(page), slug: this.$route.params.slug })
      } else {
        await this.$store.dispatch('wordpress/loadPagePosts', { currentPage: Number(page) })
      }
    },
    async fetchPosts () {
      this.loading = true
      if (!this.$route.query.page) {
        await this.changePage(1)
      } else {
        await this.changePage(this.$route.query.page)
      }
      if (this.$route.params.slug) {
        await this.$store.dispatch('wordpress/loadCategoryById', { slug: this.$route.params.slug })
        this.loading = false
      } else {
        this.$store.commit('wordpress/SET_CURRENTCATEGORY', null)
        this.loading = false
        this.$store.dispatch('wordpress/loadCategoriesPreview')
      }
    },
    getLargePostImage (post) {
      if (post.featuredImage != null && post.featuredImage.node.large != null) {
        return {
          'backgroundImage': `url(${post.featuredImage.node.large})`
        }
      } else {
        return {
          'backgroundColor': `#ccc`
        }
      }
    },
    getMediumPostImage (post) {
      if (post.featuredImage != null && post.featuredImage.node.medium != null) {
        return {
          'backgroundImage': `url(${post.featuredImage.node.medium})`
        }
      } else {
        return {
          'backgroundColor': `#ccc`
        }
      }
    },
    sanitizeHtml: sanitizeHtml
  },
  metaInfo () {
    let canonicalLink = {}
    let prevLink = {}
    let nextLink = {}
    if (this.$route.query.page) {
      canonicalLink = {
        rel: 'canonical',
        href: config.server.hostName + this.$route.path
      }
      if (parseInt(this.$route.query.page) > 1) {
        prevLink = {
          rel: 'prev',
          href: this.$route.path + '?page=' + (parseInt(this.$route.query.page) - 1)
        }
      }
      if (this.allPages > this.$route.query.page) {
        nextLink = {
          rel: 'next',
          href: this.$route.path + '?page=' + (parseInt(this.$route.query.page) + 1)
        }
      }
    }
    return {
      title: 'Blog',
      meta: [
        { vmid: 'description', name: 'description', description: 'Blog modowy dla eleganckich kobiet. Aktualne trendy, inspiracje, sesje zdjęciowe, wiadomości z targów, opinie projektantów. Do poczytania na E-Garderobe.pl!' },
        { vmid: 'og:url', property: 'og:url', content: config.server.hostName + '/pl/blog' },
        { vmid: 'og:description', property: 'og:description', content: 'Blog modowy dla eleganckich kobiet. Aktualne trendy, inspiracje, sesje zdjęciowe, wiadomości z targów, opinie projektantów. Do poczytania na E-Garderobe.pl!' },
        { vmid: 'og:title', property: 'og:title', content: 'Blog' }
      ],
      link: [
        prevLink,
        nextLink,
        canonicalLink
      ]
    }
  }
}

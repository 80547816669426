<template>
  <div class="blog-page blog-page-posts-index mb100">
    <div class="container pt10">
      <breadcrumbs
        :with-homepage="true"
        class="center-xs blog-bradcrumbs"
        :routes="[]"
        :active-route="'Blog'"
      />
      <div class="blog-page--hero">
        <p class="blog-page--page-title">
          {{ $t('Fashion Journal') }}
        </p>
      </div>
    </div>
    <div v-if="loading && !(posts && posts.length > 0)">
      <div class="blog-page--entries">
        <div class="container p0">
          <div class="row row--entries">
            <div class="col col-xs-12 col-md-6 col-lg-4">
              <div class="blog-page--preview-small">
                <p class="blog-page--preview-small-bg bg-cl-secondary" />
                <div class="blog-page--preview-small-inner">
                  <h4 class="blog-page--preview-date h-16 bg-cl-secondary w-50 brdr-radius-20" />
                  <h3 class="blog-page--preview-title h-25 mt-5 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt h-148 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt w-40 h-16 bg-cl-secondary mt-40 brdr-radius-20" />
                </div>
              </div>
            </div>
            <div class="col col-xs-12 col-md-6 col-lg-4">
              <div class="blog-page--preview-small">
                <p class="blog-page--preview-small-bg bg-cl-secondary" />
                <div class="blog-page--preview-small-inner">
                  <h4 class="blog-page--preview-date h-16 bg-cl-secondary w-50 brdr-radius-20" />
                  <h3 class="blog-page--preview-title h-25 mt-5 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt h-148 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt w-40 h-16 bg-cl-secondary mt-40 brdr-radius-20" />
                </div>
              </div>
            </div>
            <div class="col col-xs-12 col-md-6 col-lg-4">
              <div class="blog-page--preview-small">
                <p class="blog-page--preview-small-bg bg-cl-secondary" />
                <div class="blog-page--preview-small-inner">
                  <h4 class="blog-page--preview-date h-16 bg-cl-secondary w-50 brdr-radius-20" />
                  <h3 class="blog-page--preview-title h-25 mt-5 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt h-148 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt w-40 mt-40 h-16 bg-cl-secondary mt40 brdr-radius-20" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="(posts && posts.length > 0) || loading">
      <div class="container posts-conatainer p0">
        <div class="row mx0 mb20">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 p0" v-for="(post) in postList" :key="post.identifier">
            <router-link :to="localizedRoute(`/blog/post/${post.identifier}`)" class="blog-page--preview-small">
              <div class="blog-page--preview-small-inner">
                <div class="blog-page--image-box">
                  <img v-lazy="post.featured_image" alt="post image" width="100%" height="100%">
                </div>
                <div class="blog-page--data-box">
                  <h2 class="blog-page--preview-title" v-html="post.title" />
                  <h4 class="blog-page--preview-date">
                    {{ post.publish_time | prettyDate }}
                  </h4>
                  <h3 class="blog-page--preview-excerpt no-break weight-400" v-html="sanitizeHtml(post.short_filtered_content, { allowedTags: [], allowedAttributes: {}, textFilter})" />
                  <h3 class="blog-page--read-more">
                    {{ $t('Read more') }}
                  </h3>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-xs-12">
            <BlogPagination
              v-show="allPages > 1"
              class="blog-pagination my20 flex center-xs middle-xs"
              :current="currentPage"
              :total="allPages"
              :visible="8"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h3>{{ $t('There are no posts.') }}</h3>
    </div>
  </div>
</template>

<script>
import Posts from 'src/modules/vsf-wordpress/components/posts/Index.js'
import Loader from 'theme/components/core/LoadingSpinner.vue'
import BlogPagination from 'theme/components/core/blocks/Wordpress/posts/BlogPagination'

export default {
  components: {
    Loader,
    BlogPagination
  },
  mixins: [Posts],
  name: 'PostsIndex',
  computed: {
    postList () {
      if (this.posts && this.posts.length > 0) {
        return this.posts
      } else {
        return []
      }
    }
  },
  mounted () {
  },
  methods: {
    textFilter (text, tagName) {
      if (text === 'Continued' && tagName === 'a') {
        return text.replace('Continued', '')
      } else {
        return text
      }
    }
    // async onBottomScroll () {
    //   if (this.loading || this.allPages <= this.currentPage) return
    //   this.loading = true
    //   try {
    //     await this.$store.dispatch('wordpress/loadPagePosts', { currentPage: this.currentPage + 1 })
    //   } catch (e) {
    //     console.error('Problem with fetching more posts')
    //   } finally {
    //     this.loading = false
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/modules/vsf-wordpress/components/blog/scss/blog';

.blog-page--preview-small-inner {
  display: flex;
  flex-direction: row;
}
.blog-page--data-box {
  @media only screen and (max-width: 570px) {
    padding-left: 0;
    max-width: 100%;
    flex-basis: 100%;
    text-align: center;
  }
  flex-basis: 60%;
  max-width: 60%;
  padding-left: 30px;
  position: relative;
}
.blog-page--image-box {
  @media only screen and (max-width: 570px) {
    padding-top: calc(100% * 1/0.9);
  }
  flex-basis: 40%;
  position: relative;
  display: flex;
  overflow: hidden;
  height: 0;
  padding-top: calc(100% * 1/2);
  img {
    position: absolute;
    top: 0;
    object-fit: cover;
    transition: 0.3s all;
    &:hover {
      transform: scale(1.2);
    }
  }
}
.loader-text {
  font-size: 18px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.blog-page-posts-index {
  .blog-page--hero {
    padding-top: 10px;
    text-align: center;
    @media (max-width: 987px) {
      padding-bottom: 20px;
    }
    @media (max-width: 767px) {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
  .blog-page--page-title {
    @media (max-width: 767px) {
      margin-top: 0px;
      font-size: 24px;
      margin-bottom: 0px;
    }
    display: inline;
    text-align: center;
    color: $nearly-black;
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 35px;
    font-family: 'Angelica Pro Display';
  }
}
.category-filters {
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
  border: 1px solid #CCCCCC;
  padding: 10px 20px;
  border-radius: 10px;
  .filter-label {
    color: black;
    &:hover {
      &::after {
        content: "";
        position: absolute;
        left: 8px;
        top: 5px;
        border-bottom: 2px solid #FFF;
        border-right: 2px solid #FFF;
        transform: rotate(45deg);
        width: 5px;
        height: 10px;
      }
    }
    &::before {
      content: "";
      position: relative;
      top: 1px;
      border-radius: 4px;
      margin-right: 20px;
      min-width: 20px;
      min-height: 20px;
      border: 1px solid #CCCCCC;
    }
    &.active {
      &::after {
        content: '';
        position: absolute;
        left: 8px;
        top: 5px;
        width: 5px;
        height: 10px;
        border-bottom: 2px solid #FFF;
        border-right: 2px solid #FFF;
        transform: rotate(45deg);
      }
    }
  }
}
.show-more {
  color: #008329;
  font-weight: 700;
  font-size: 18px;
}
.blog-page--preview-large-inner {
  @media (max-width: 767px) {
    border-radius: 7px !important;
    padding: 15px !important;
    .blog-page--preview-date {
      text-align: right;
    }
    .blog-page--preview-title {
      font-size: 18px !important;
      line-height: 26px !important;
    }
  }
}
  .no-break {
    word-break: break-word;
  }
  .h-44 {
    height: 44px;
  }
  .h-25 {
    height: 25px;
  }
  .h-16 {
    height: 16px;
  }
  .h-148 {
    height: 148px;
  }
  .w-40 {
    width: 40%;
  }
  .mt-40 {
    margin-top: 40px !important;
  }
  .mt-5 {
    margin-top: 5px !important;
  }
  .category-link {
    white-space: nowrap;
  }
  .category-link:hover {
    text-decoration: underline;
  }
  .blog-bradcrumbs {
    justify-content: center !important;
  }
</style>

<template>
  <nav class="pagination">
    <slot name="prev" v-bind="{ isDisabled: !canGoPrev, go, prev: getPrev }">
      <router-link
        v-show="canGoPrev"
        :disabled="!canGoPrev ? true : false"
        aria-label="Go to previous page"
        :to="getLinkTo(getPrev)"
        class="flex middle-xs h4 pointer pagination__button"
      >
        <i class=" icon-chevron-left" />
      </router-link>
    </slot>
    <template v-if="showFirst">
      <slot name="number" v-bind="{ page: 1 }">
        <router-link
          class="pagination__item pointer button--pure"
          :to="getLinkTo(1)"
        >
          1
        </router-link>
      </slot>
      <slot v-if="firstVisiblePageNumber > 2" name="points">
        <div class="pagination__item">
          ...
        </div>
      </slot>
    </template>
    <template v-for="page in limitedPageNumbers">
      <slot name="number" v-bind="{ page, currentPage }">
        <router-link
          :key="page"
          class="pagination__item pointer"
          :class="{
            'button--pure': currentPage !== page,
            current: currentPage === page,
          }"
          :to="getLinkTo(page)"
        >
          {{ page }}
        </router-link>
      </slot>
    </template>
    <slot name="next" v-bind="{ isDisabled: !canGoNext, go, next: getNext }">
      <router-link
        v-show="canGoNext"
        :disabled="!canGoNext ? true : false"
        aria-label="Go to previous page"
        :to="getLinkTo(getNext)"
        class="flex middle-xs h4 pointer pagination__button"
      >
        <i class="icon-chevron-right" />
      </router-link>
    </slot>
  </nav>
</template>
<script>
export default {
  name: 'ListingPagination',
  props: {
    total: {
      type: Number,
      default: 0
    },
    visible: {
      type: Number,
      default: 5
    },
    current: {
      type: Number,
      default: 1
    }
  },
  computed: {
    currentPage () {
      return this.current;
    },
    getPrev () {
      return this.currentPage === this.firstVisiblePageNumber
        ? this.currentPage
        : this.currentPage - 1;
    },
    canGoPrev () {
      return this.currentPage !== this.firstVisiblePageNumber
    },
    getNext () {
      return this.currentPage === this.lastVisiblePageNumber
        ? this.currentPage
        : this.currentPage + 1;
    },
    canGoNext () {
      return this.currentPage !== this.lastVisiblePageNumber
    },
    showFirst () {
      return this.firstVisiblePageNumber > 1;
    },
    showLast () {
      return this.lastVisiblePageNumber < this.total
    },
    listOfPageNumbers () {
      return Array.from(Array(this.total), (_, i) => i + 1)
    },
    limitedPageNumbers () {
      if (this.total <= this.visible) {
        return this.listOfPageNumbers;
      }
      if (this.currentPage < this.visible - Math.floor(this.visible / 2) + 1) {
        return this.listOfPageNumbers.slice(0, this.visible);
      }
      if (
        this.total - this.currentPage <
        this.visible - Math.ceil(this.visible / 2) + 1
      ) {
        return this.listOfPageNumbers.slice(this.total - this.visible);
      }
      return this.listOfPageNumbers.slice(
        this.currentPage - Math.ceil(this.visible / 2),
        this.currentPage + Math.floor(this.visible / 2)
      );
    },
    firstVisiblePageNumber () {
      return this.limitedPageNumbers[0];
    },
    lastVisiblePageNumber () {
      return this.limitedPageNumbers[this.limitedPageNumbers.length - 1];
    }
  },
  methods: {
    getLinkTo (page) {
      return { path: this.$route.path, query: { page: page } }
    },
    go (page) {
      if (this.$route.params.slug) {
        this.$store.dispatch('wordpress/loadCategoryPosts', { currentPage: page, slug: this.$route.params.slug })
      } else {
        this.$store.dispatch('wordpress/loadPagePosts', { currentPage: page })
      }
    },
    goToLink (page) {
      this.$router.push({ path: this.$route.path, query: { page: page } })
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination {
  &__button {
    margin-left: 2px;
    margin-right: 2px;
    color: #343434;
    border: 1px solid #343434;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__item {
    margin-left: 2px;
    margin-right: 2px;
    color: #343434;
    border: 1px solid #343434;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__item.current {
    color: #ffffff;
    background-color: #343434;
  }
}
#paginationInput {
  border: 1px solid #D9D9D9;
    border-radius: 19px;
    padding: 9px;
    font-size: 14px;
    text-align: center;
    width: 16px;
    line-height: 11px;
    height: 16px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0 3px;
}
#paginationInput::-webkit-outer-spin-button,
#paginationInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
